<template>
  <v-row
    class="verification-code-inputs-container px-md-3 space-between" 
    justify="center"
    no-gutters
  >
    <v-col
      v-for="(value,index) in codeLength"
      :key="(index+1)"
      class="col-auto spacing"
    >
      <input
        :id="indexToString(index+1)"
        type="number"
        maxlength="1"
        class="resp-input-code heading heading-s-large m-2 text-center form-control rounded"
        data-test-id="phoneCode"
        @input="typedCode($event, index)"
      >
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    codeLength: 6,
    confirmationCode: null,
    inputsValues: null,
    enumNumber: { TOP_LOWER_NUMBER: 47, TOP_UPPER_NUMBER: 58, PAD_LOWER_NUMBER: 95, PAD_UPPER_NUMBER: 106 },
    enumKey: { backspace: "Backspace", backspaceMobile: "deleteContentBackward" },
  }),
  mounted() {
    this.inputsValues = [];
  },
  methods: {
    indexToString(value) {
      return value.toString();
    },
    isKeyNumber(number) {
      return number >= 0 && number <= 9;
    },
    typedCode(event, index) {
      const inputType = event.inputType;
      const parsedData = parseInt(event.data);

      const inputs = document.querySelectorAll(".verification-code-inputs-container .resp-input-code");
      
      if ( event.key === this.enumKey.backspace || inputType === this.enumKey.backspaceMobile) {
        inputs[index].value = '';
        this.inputsValues.splice(index, 1);
        if ( index !== 0 ) {
          inputs[index - 1].focus()
        }
        return this.$emit("update-confirmation-code", this.inputsValues);
      } 

      if (!this.isKeyNumber(parsedData)) {
        inputs[index].value = '';
      }
      
      if ( index === inputs.length - 1 && inputs[index].value !== '' && this.inputsValues[index] === inputs[index].value ) {
        return true;
      } 

      inputs[index].value = parsedData;
      this.inputsValues[index] = parsedData;

      if (index !== inputs.length - 1) {
        inputs[index + 1].focus();
      }

      this.$emit("update-confirmation-code", this.inputsValues);
    }
  }
}
</script>

<style scoped>
  .v-input >>> input {
    font-size: 24px;
    text-align: center;
  }
  .theme--light.v-input >>> input {
    color: #484848 !important;
    opacity: 1.0;
    font-weight: 700;
  }
  .v-text-field--outlined >>> fieldset {
    color: #ACB5C2 !important;
    border: 1px solid #ACB5C2 !important;
  }
  @media (max-width: 320px) { 
    input.resp-input-code {
    width: 33px !important;
  }
  }

  @media (max-width: 599px) {
    .resp-input-code >>> .v-input__slot {
      padding: 0 6px !important;
      min-height: 40px !important;
    }
  }
  input.resp-input-code {
    width: 40px;
    height: 40px;
    border: 0px solid #ACB5C2 !important;
    background-color: white;
  }
  input.resp-input-code:focus-visible {
    outline: #1DA1F2 solid 1px !important;
  }
  .form-control:focus {
    box-shadow: none;
  }

  @media (min-width: 600px) { 
    .space-between {
      justify-content: space-around !important;
    }
  }

  .spacing {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  @media (max-width: 375px) { 
    .spacing {
      padding-left: 3px !important;
      padding-right: 2px !important;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0
  }
</style>
