<template>
  <div class="text-left">
    <label class="text text-m-small-bold">{{ label }}</label>
    <v-text-field
      v-model="password"
      height="56"
      dense
      outlined
      name="password"
      class="field-background-white"
      :type="showPassword ? 'text' : 'password'"
      :placeholder="placeholder"
      :rules="[rules.required, rules.password]"
      required
      data-test-id="password"
      @click:append="showPassword = !showPassword"
    >
      <template #append>
        <ph-eye
          v-show="showPassword"
          weight="thin"
          size="24"
          class="clickable"
          @click="showPassword = !showPassword"
        />
        <ph-eye-slash
          v-show="!showPassword"
          weight="thin"
          size="24"
          class="clickable"
          @click="showPassword = !showPassword"
        />
      </template>
    </v-text-field>
  </div>
</template>

<script>
import ValidatorComponent from '@/components/common/ValidatorComponent';
import { PhEye, PhEyeSlash } from "phosphor-vue";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { ValidatorComponent, PhEye, PhEyeSlash },
  props: ['label', 'placeholder'],
  data: () => ({
    showPassword: false,
    password: null,
    rules: ValidatorComponent.data().rules,
  }),
  watch: {
    password: function (newPassword) {
      this.$emit('new-password', newPassword);
    }
  },
}
</script>
<style scoped>

  .field-background-white >>> input::placeholder {
    color: var(--v-grey-darken4) !important;
  }

  .v-input >>> .v-input__append-inner {
    margin-top: 0 !important;
    align-self: center !important;
  }

  .v-text-field--outlined >>> fieldset {
    border: 1px solid var(--v-grey-darken4);
    background-color: transparent !important;
  }

  .field-background-white >>> .v-icon {
    color: var(--v-grey-darken4) !important;
  }

</style>
