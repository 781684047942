<template>
  <div class="row justify-center no-gutters">
    <v-col
      cols="12"
      sm="12"
      md="11"
      lg="8"
    >
      <v-tabs
        v-model="tab"
        background-color="white"
        show-arrows
        class="responsive-profile-tab-container"
      >
        <v-tabs-slider color="primary" />
        <v-tab
          v-for="profileTab in profileTabs"
          :key="profileTab.key"
          class="full-width-tabs"
        >
          <span :class="{'text-uppercase': profileTab.title !== 'Acapedia'}">{{ profileTab.title }}</span> 
        </v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="tab"
        class="fill-height theme-background-color-feed"
        touchless
      >
        <v-tab-item
          v-for="profileTab in profileTabs"
          :key="profileTab.key"
        >
          <v-row
            justify="center"
            class="mt-4"
          >
            <v-col
              v-if="profileTab.tab === tabsEnum.account"
              cols="11"
              sm="11"
              lg="12"
            >
              <v-row>
                <v-col
                  cols="11"
                  sm="6"
                  lg="6"
                >
                  <v-card class="card-box pa-3 mb-4">
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="mb-2"
                      >
                        <h6 class="heading heading-m-small">
                          My transcripts
                        </h6>
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          block
                          color="primary"
                          ripple
                          depressed
                          @click="goToLayout('transcripts')"
                        >
                          SEE MY TRANSCRIPTS
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card class="card-box pa-3 mb-4">
                    <v-row
                      no-gutters
                      justify="center"
                    >
                      <v-col
                        cols="12"
                        class="mb-2"
                      >
                        <h6 class="heading heading-m-small">
                          My specialties
                        </h6>
                      </v-col>
                      <v-col
                        cols="11"
                        class="mt-3"
                      >
                        <v-row
                          v-for="specialty,key in userSpecialties"
                          :key="key+uniqueKeysHelper()"
                        >
                          <v-col
                            cols="12"
                            class="specialty-pill-list d-flex justify-space-between mb-2"
                          >
                            <span class="text text-l-large-regular specialty-pill-list-text">{{ specialty.title }}</span>
                            <uis-check-circle
                              size="24px"
                              class="logo primary--text"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          block
                          color="primary"
                          ripple
                          depressed
                          class="mt-3"
                          @click="goToLayout('specialties')"
                        >
                          EDIT MY SPECIALTIES
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card class="card-box pa-3 mb-4">
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="mb-2"
                      >
                        <h6 class="heading heading-m-small">
                          My keywords
                        </h6>
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          block
                          color="primary"
                          ripple
                          depressed
                          @click="goToLayout('keywords')"
                        >
                          EDIT MY KEYWORDS
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col
                  cols="11"
                  sm="6"
                  lg="6"
                >
                  <PersonalProfileFieldsComponent />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              v-if="profileTab.tab === tabsEnum.account"
              cols="11"
              sm="11"
              lg="12"
            >
              <LogoutComponent />
              <DeleteAccountComponent />
            </v-col>
            <v-col
              v-if="profileTab.tab === tabsEnum.plan"
              cols="11"
              sm="12"
              lg="12"
            >
              <SubscriptionPlansComponent />
            </v-col>
            <v-col
              v-if="profileTab.tab === tabsEnum.billing"
              cols="11"
              sm="11"
              lg="12"
            >
              <BillingComponent />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </div>
</template>

<script>
import PersonalProfileFieldsComponent from "@/components/profile/PersonalProfileFieldsComponent";
import DeleteAccountComponent from "@/components/profile/DeleteAccountComponent";
import LogoutComponent from "@/components/profile/LogoutComponent";
const SubscriptionPlansComponent = () => import(/* webpackChunkName: "SubscriptionPlansComponent" */ "@/components/subscriptions/SubscriptionPlansComponent");
const BillingComponent = () => import(/* webpackChunkName: "BillingComponent" */ "@/components/billing/BillingComponent");
import { UisCheckCircle } from '@iconscout/vue-unicons-solid';
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: { PersonalProfileFieldsComponent, DeleteAccountComponent, LogoutComponent, SubscriptionPlansComponent, BillingComponent, UisCheckCircle },
  data: () => ({
    profileTabs: [{ tab: 0, title: 'Account', key: uuidv4() }, { tab: 1, title: 'Plan', key: uuidv4()}, {tab: 2, title: 'Billing', key: uuidv4()}],
    tab: 0,
    tabsEnum: { account: 0, plan: 1, billing: 2 },
    userSpecialties: null,
    uniqueKeysHelper: uuidv4
  }),
  computed: {
    ...mapGetters('specialties', ['currentSelecteds']),
  },
  methods: {
    ...mapActions('menu', ['hideTopMenu', 'setTopMenuTitle']),
    ...mapActions('specialties', ['getAll', 'getSpecialtiesSelected']),
    goToLayout(layout) {
      this.setTopMenuTitle(layout);
      this.$router.push({ name: layout });
    },
  },
  async mounted() {
    this.setTopMenuTitle('Profile');
    this.hideTopMenu(true);

    await this.getSpecialtiesSelected();
    this.userSpecialties = this.currentSelecteds.specialties;
  }
}
</script>
<style scoped>
.theme-background-color-feed {
  background-color: var(--v-background-base) !important;
}
.full-width-tabs {
  flex: 1;
}
div.v-card.card-box {
  box-shadow: none;
  border-radius: 16px;
}
.specialty-pill-list {
  background:  var(--v-background-base);
  border-radius: 8px;
}
.specialty-pill-list-text {
  color: #171725;
}
@media (min-width: 810px) and (max-width: 1024px) {
  .responsive-profile-tab-container >>> .v-item-group {
    background-color: var(--v-background-base) !important;
  }
}
@media (min-width: 960px) {
  .responsive-profile-tab-container >>> .v-item-group {
    background-color: var(--v-background-base) !important;
  }
}
</style>
