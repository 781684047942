<template>
  <v-row justify="center">
    <v-col
      cols="12"
      sm="10"
      md="12"
      lg="10"
    >
      <new-simple-unit-input-component @update-confirmation-code="updateConfirmationCode" />
    </v-col>
    <v-col
      cols="12"
      class="pb-0"
    >
      <h5 class="theme-default-label text text-m-large-regular mb-1">
        The code will expire in
      </h5>
      <span class="theme-default-label text text-l-large-bold">{{ counterPretty }}</span>
    </v-col>
    <v-col 
      cols="12" 
      class="actions"
    >
      <slot name="continue" />
      <v-card-actions
        class="container-actions"
      >
        <v-btn
          :width="220"
          ripple
          block
          large
          class="heading heading-s-small primary--text white shadow-light-button"
          :disabled="(!suggestResendCode || counter > 0) && isSent"
          @click="resendCode"
        >
          Resend code
        </v-btn>
      </v-card-actions>
    </v-col>
    <v-col 
      class="lg-8 pt-1 pt-lg-4"
    >
      <span
        class="text text-l-large-regular-link primary--text clickable mb-4"
        @click="handleChangeNumber"
      >
        Change my phone number
      </span>
    </v-col>
  </v-row>
</template>

<script>
  import NewSimpleUnitInputComponent from './NewSimpleUnitInputComponent';
  export default {
    components: { NewSimpleUnitInputComponent },
    props: ['suggestResendCode'],
    data: () => ({
      counter: 120,
      fullCode: null,
      isSent: true,
      intervalRef: null,
      TAB_CHANGE_NUMBER: 2,
      MAX_DIGITS: 6
    }),
    computed: {
      counterPretty() {
        let min = Math.floor(this.counter % 3600 / 60).toString().padStart(2,'0');
        let sec = Math.floor(this.counter % 60).toString().padStart(2,'0');

        return  min + ':' + sec;
      },
    },
    mounted() {
      this.intervalRef = setInterval( () => {
        if(this.counter > 0) {
          this.counter--;
        } else {
          this.isSent = false;
        }
      }, 1000);
    },
    unmounted() {
      clearInterval(this.intervalRef);
    },
    methods: {
      resendCode() {
        if(!this.isSent) {
          this.counter = 120;
          this.isSent = true;
          this.$emit('resend-code');
        }
      },
      updateConfirmationCode(inputsValues) {
        this.fullCode =  inputsValues.map( function (inputValue) {
          return inputValue;
        }).join('');

        this.$emit('full-code', this.removeExtraDigits(this.fullCode, inputsValues.length));
      },
      handleChangeNumber() {
        this.$emit('change-number', this.TAB_CHANGE_NUMBER);
      },
      removeExtraDigits(fullCode, length) {
        if (length > this.MAX_DIGITS ) {
          return fullCode.slice(0, this.MAX_DIGITS);
        }

        return fullCode.slice(0, length)
      }
    }
  }
</script>
<style scoped>
  .container-actions {
    flex-wrap: wrap;
    bottom: 15px;
    margin-left: 0px;
    width: 100%;
  }

  @media (min-width: 1440px)  and (orientation: landscape) {
    .container-actions {
      padding-right: 14px;
    }

    .actions {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-direction: row-reverse
    }
  }
</style>