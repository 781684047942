<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-btn
          text
          :ripple="false"
          color="primary"
          class="px-1"
          @click="performLogout"
        >
          <v-icon
            left
            color="primary"
          >
            mdi-logout-variant
          </v-icon>
          Logout
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('menu', ['hideTopMenu', 'setTopMenuTitle']),
    async performLogout() {
      await this.logout();
      document.cookie = "";
      this.hideTopMenu(false);
      this.setTopMenuTitle('');
      this.$router.push('/login');
    },
  }
}
</script>
