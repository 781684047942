<template>
  <div>
    <v-row
      justify="center"
      no-gutters
    >
      <v-col cols="12">
        <v-card class="card-box pa-3 mb-4">
          <v-row no-gutters>
            <v-col
              cols="12"
              class="mb-2"
            >
              <h6 class="heading heading-m-small">
                Personal Information
              </h6>
            </v-col>
            <v-col cols="12">
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="text-center"
                >
                  <v-text-field
                    v-model="firstName"
                    readonly
                  >
                    <template #prepend-inner>
                      <v-icon color="primary">
                        mdi-account-circle-outline
                      </v-icon>
                    </template>
                    <template #append>
                      <v-btn
                        icon
                        color="secondary"
                        @click="editField(enumOptions.editNames)"
                      >
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="text-center"
                >
                  <v-text-field
                    v-model="lastName"
                    :prepend-inner-icon="'mdi-account-circle-outline'"
                    readonly
                  >
                    <template #prepend-inner>
                      <v-icon color="primary">
                        mdi-account-circle-outline
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    v-model="email"
                    readonly
                  >
                    <template #prepend-inner>
                      <v-icon color="primary">
                        mdi-email-outline
                      </v-icon>
                    </template>
                    <template #append>
                      <v-btn
                        v-if="userGoogleId == null"
                        icon
                        color="secondary"
                        @click="editField(enumOptions.editEmail)"
                      >
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    v-model="phoneNumber"
                    readonly
                  >
                    <template #prepend-inner>
                      <v-icon
                        color="primary"
                        class="mt-1"
                      >
                        mdi-phone-outline
                      </v-icon>
                    </template>
                    <template #append>
                      <v-btn
                        icon
                        color="secondary"
                        @click="editField(enumOptions.editPhone)"
                      >
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row
                v-if="userGoogleId == null"
                no-gutters
              >
                <v-col cols="12">
                  <v-text-field
                    value="••••••••"
                    readonly
                  >
                    <template #prepend-inner>
                      <v-icon
                        color="primary"
                        class="mt-1"
                      >
                        mdi-lock-outline
                      </v-icon>
                    </template>
                    <template #append>
                      <v-btn
                        icon
                        color="secondary"
                        @click="editField(enumOptions.editPassword)"
                      >
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    firstName: null,
    lastName: null,
    email: null,
    googleId: null,
    phoneNumber: null,
    enumOptions: { editEmail: "email", editPhone: "phone", editPassword: "password", editNames: "names" },
  }),
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('profile', ['currentProfile']),
    fullName() {
      return this.currentProfile ? `${this.currentProfile.firstName} ${this.currentProfile.lastName}` : "Unknown";
    },
    userGoogleId() {
      return this.currentUser?.google_id;
    }
  },
  methods: {
    ...mapActions('menu', ['hideTopMenu', 'setTopMenuTitle']),
    editField(field) {
      this.setTopMenuTitle('Edit '+field);
      this.$router.push({ name: 'profile-edit', params: { field: field }});
    },
  },
  mounted() {
    this.firstName = this.currentProfile.firstName;
    this.lastName = this.currentProfile.lastName;
    this.email = this.currentUser.email;
    this.phoneNumber = this.currentProfile.phoneNumber;
  }
}
</script>

<style scoped>
.theme-default-chip {
  display: flex;
  min-width: 234px;
  height: 56px !important;
}

.theme-sm-chip {
  width: 187px;
  height: 38px !important;
}

.theme-default-chip-input, .theme-default-chip-input:hover {
  background: #F1F1F5 !important;
  border-radius: 10px !important;
}

.theme-default-chip-file, .theme-default-chip-file:hover {
  background: #E2E2EA !important;
  border-radius: 10px !important;
}

.v-chip.theme-default-chip-input:before {
  background-color: initial;
}

.v-chip.theme-default-border {
  border-radius: 10px !important;
}

.clickable:hover {
  cursor: pointer;
}

.v-input >>> input, .theme-default-chips-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.5px !important;
  color: #212121 !important;
}

.row-fixed {
  position: fixed;
  bottom: 0px;
  width: 100%;
  margin: auto;
}

.btn-theme-icon {
  width: 56px !important;
  height: 56px !important;
}

div.v-card.card-box {
  box-shadow: none;
  border-radius: 16px;
}
</style>
