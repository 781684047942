import { render, staticRenderFns } from "./ProfileComponent.vue?vue&type=template&id=dd2ac024&scoped=true&"
import script from "./ProfileComponent.vue?vue&type=script&lang=js&"
export * from "./ProfileComponent.vue?vue&type=script&lang=js&"
import style0 from "./ProfileComponent.vue?vue&type=style&index=0&id=dd2ac024&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd2ac024",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCard,VCol,VRow,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider})
