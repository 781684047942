<template>
  <div>
    <v-row
      no-gutters
      class="mt-5"
    >
      <v-col
        cols="12"
        sm="6"
      >
        <v-btn
          text
          ripple
          depressed
          color="red lighten-1"
          class="px-0"
          @click="editDeleteAccount"
        >
          DELETE ACCOUNT
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="deleteAccountDialog"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Delete account
        </v-card-title>
        <v-card-text class="mt-4">
          Are you sure you want to delete your account? 
          This action is permanent and you will no longer have access to your credits...
        </v-card-text>
        <v-card-text v-if="deleteAccepted">
          <v-col v-if="!userGoogleId">
            <PasswordComponent
              :label="'Password'"
              :placeholder="'Enter your password'"
              @new-password="setPasswordToUnlockEdit"
            />
          </v-col>
          <v-col
            v-if="userGoogleId"
            class="text-center"
          >
            <ConfirmCodeComponent @full-code="setCodeToUnlockEdit" />
          </v-col>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="secondary"
            text
            @click="cancelDelete"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="!deleteAccepted"
            color="primary"
            text
            @click="acceptDelete"
          >
            I accept
          </v-btn>
          <v-btn
            v-if="deleteAccepted"
            color="red lighten-1"
            text
            @click="confirmDelete"
          >
            DELETE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <input
      ref="currentUserEmail"
      type="text"
      class="d-none"
      :value="currentUser != null ? currentUser.email : null"
    >
    <input
      ref="currentUserGoogleId"
      type="text"
      class="d-none"
      :value="currentUser != null ? currentUser.google_id : null"
    >
  </div>
</template>

<script>
import ConfirmCodeComponent from  '@/components/common/ConfirmCodeComponent';
import PasswordComponent from '@/components/common/PasswordComponent';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: { ConfirmCodeComponent, PasswordComponent },
  data: () => ({
    deleteAccountDialog: false,
    deleteAccepted: false,
  }),
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('profile', ['currentProfile']),
    userGoogleId() {
      return this.currentUser?.google_id;
    }
  },
  methods: {
    ...mapActions('auth', ['deleteAccount', 'sendDeleteCode', 'confirmDeleteCode']),
    editDeleteAccount() {
      this.deleteAccountDialog = true;
    },
    acceptDelete() {
      this.deleteAccepted = true;

      if(this.$refs.currentUserGoogleId.value) {
        this.sendDeleteCode().then( response => {
          this.$toasted.global.showSuccess({message: response.data.message});
        }).catch( error => {
          this.$toasted.global.showError({message:  error.response.data ?  error.response.data.message : 'Unexpected error, please try again'});
        });
      }
    },
    cancelDelete() {
      this.deleteAccountDialog = false;
      this.deleteAccepted = false;
    },
    confirmDelete() {
      this.email = this.$refs.currentUserEmail.value;
      this.googleId = this.$refs.currentUserGoogleId.value;

      if(this.googleId) {
          this.confirmDeleteCode({ code: this.fullCode }).then( () => {
            this.doDelete();
          }).catch( error => {
            this.$toasted.global.showError({message: error.response.data ?  error.response.data.message : 'Code verification failed, please try again'});
          });
      } else {
        this.doDelete();
      }
    },
    doDelete() {
      this.deleteAccount({
        params: {
          email: this.email,
          password: this.password,
          googleId: this.googleId,
        }
      }).then( response => {
        this.$toasted.global.showSuccess({message: response.data.message});
        this.$router.push({ name: 'home' });
      }).catch( error => {
        this.$toasted.global.showError({message: error.response.data?.message || error.message});
      });
    },
    setPasswordToUnlockEdit(password) {
      this.password = password;
    },
    setCodeToUnlockEdit(fullCode) {
      this.fullCode = fullCode;
    },
  }
}
</script>
